import { parseStrings } from "./studentDataProcessing";
import { includesInsensitive, compareInsensitive } from "./compare";
import { CELL_VALUE_TYPE } from "constants/metadata/cell_value_type";
import { DATA_TYPE } from "constants/metadata/data_type";

export const rowsByKeys = (rows, keys) => {
  if (keys.length === 0) return rows; //an empty array means "all"

  return rows.map(row => ({
    ...row,
    cells: row.cells.filter(cell => keys.includes(cell.key)),
  }));
};

export const colsByKeys = (columns, keys) => {
  if (keys.length === 0) return columns; //an empty array means "all"

  return columns.filter(column => keys.includes(column.key));
};

// We can abstract out more in the future to search by any key, or multiple.
export const rowsByName = (rows, name) => {
  rows.forEach(row => {
    const nameCell = row.cells.find(cell => cell.key === "name");
    const strippedName = nameCell.value.replace(/,/g, "");

    if (
      nameCell &&
      (includesInsensitive(nameCell.value, name) ||
        includesInsensitive(strippedName, name))
    ) {
      row.filterOut = false;
    } else {
      row.filterOut = true;
    }
  });
  return rows;
};

// This function is used to update the rows in the DataGridProvider
// given an event to handle
export const updateStudentRows = (event, currRows, currColumns) => {
  const { name, value, checked } = event.target;
  // eslint-disable-next-line no-unused-vars
  const [key, uuid, __] = name.split("_");
  const formattedKey = key.replace(/-/g, "_");
  const colDependency = currColumns.find(col => col.dependency === key);
  const column = currColumns.find(col => col.key === key);

  const updatedRows = currRows.map(row => {
    if (row.uuid === uuid) {
      const updatedCells = row.cells.map(cell => {
        if (cell.key === key) {
          return { ...cell, value };
        }
        if (cell.key === colDependency?.key) {
          return {
            ...cell,
            editable: value === column?.noneLabel ? false : true,
          };
        }
        return cell;
      });

      return {
        ...row,
        [formattedKey]: parseStrings(
          key,
          checked !== undefined ? checked : value
        ),
        cells: updatedCells,
      };
    }
    return row;
  });

  return updatedRows;
};

export const labelLookup = (value, column) => {
  if (column.options) {
    return (
      column.options.find(opt => compareInsensitive(opt.value, value))?.label ||
      value
    );
  }
  return value;
};

export const cellType = (allowEdits, column, cell) => {
  if (
    allowEdits &&
    column.editable &&
    (cell.editable == undefined || cell.editable)
  ) {
    return CELL_VALUE_TYPE.EDITABLE;
  }
  // For multiselect,
  // DataGrid edits aren't allowed so we want to display the values selected
  // Column edits aren't allowed so we want to display the values selected
  if (
    column.dataType == DATA_TYPE.MULTISELECT &&
    (!allowEdits || !column.editable) &&
    column.options
  ) {
    return CELL_VALUE_TYPE.MULTI_SELECT_READ_ONLY;
  }
  // For multiselect,
  // We've disabled this cell so we don't want to display the values selected
  if (column.dataType == DATA_TYPE.MULTISELECT && column.options)
    return CELL_VALUE_TYPE.MULTI_SELECT_DISABLED;
  if (column.options) return CELL_VALUE_TYPE.SELECT_READ_ONLY;
  return CELL_VALUE_TYPE.READ_ONLY;
};
