import { useContext, useEffect, useState } from "react";
import { DataGridContext } from "./DataGridProvider";
import PropTypes from "prop-types";
import DataGridRowBodyCell from "./Cells/DataGridRowBodyCell";
import DataGridRowHeaderCell from "./Cells/DataGridRowHeaderCell";
import DataGridHeaderCell from "./Cells/DataGridHeaderCell";
import classNames from "classnames";

function DataGridRow({
  cells,
  uuids,
  selected,
  rowIndex,
  onEdit,
  saveAsYouGo = false,
  newRow = false,
}) {
  const {
    currRows,
    currColumns,
    multiSelect,
    disableSelect,
    allowEdits,
    onSelect,
    onEditSaveProgress,
  } = useContext(DataGridContext);

  const headerCell = cells[0];
  const totalCols = currColumns.length;
  const totalRows = currRows.length;

  const bodyCells = cells.slice(1);
  const bodyCols = currColumns.slice(1);
  const hasUpdateableCells = cells.some(cell => !cell.value);
  const [hasCellErrors, setHasCellErrors] = useState(
    cells.some(cell => cell.error)
  );
  const disabled = disableSelect && !selected;

  useEffect(() => {
    setHasCellErrors(currRows[rowIndex - 2].cells.some(cell => cell.error));
  }, [currRows]);

  const onEditCombined = event => {
    onEdit(event);
    if (!saveAsYouGo) {
      onEditSaveProgress(event);
    }
  };

  const onSelectCombined = event => {
    onSelect(event);
    if (!saveAsYouGo) {
      onEditSaveProgress(event);
    }
  };

  return (
    <tr
      className={classNames({
        "bg-ignite-purple-highlight": newRow,
      })}
      aria-rowindex={rowIndex}
      aria-selected={selected}>
      <DataGridRowHeaderCell
        cell={headerCell}
        rowIndex={rowIndex}
        totalRows={totalRows}
        allowEdits={allowEdits}
        multiSelect={multiSelect}
        hasUpdateableCells={hasUpdateableCells}
        hasCellErrors={hasCellErrors}
        selected={selected}
        onSelect={onSelectCombined}
        disabled={disabled}
        uuids={uuids}
        newRow={newRow}
      />
      {bodyCells.map((cell, i) => (
        <DataGridRowBodyCell
          key={i}
          cell={cell}
          cellIndex={i}
          rowIndex={rowIndex}
          column={bodyCols[i]}
          totalRows={totalRows}
          totalCols={totalCols}
          allowEdits={allowEdits}
          disabled={disabled}
          uuids={uuids}
          onEdit={onEditCombined}
        />
      ))}
    </tr>
  );
}

DataGridRow.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool.isRequired,
    })
  ).isRequired,
  uuids: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  saveAsYouGo: PropTypes.bool,
  newRow: PropTypes.bool,
};

function DataGridHeaderRow({ scrolling = false }) {
  const { currColumns, toggleSort } = useContext(DataGridContext);

  const totalCols = currColumns.length;

  return (
    <thead className="border-b-[.75px] border-ignite-grey-border">
      <tr aria-rowindex="1">
        {currColumns.map((col, i) => (
          <DataGridHeaderCell
            key={i}
            column={col}
            columnIndex={i}
            scrolling={scrolling}
            toggleSort={toggleSort}
            totalCols={totalCols}
          />
        ))}
      </tr>
    </thead>
  );
}

DataGridHeaderRow.propTypes = {
  allSelected: PropTypes.bool,
  scrolling: PropTypes.bool,
};

export { DataGridRow, DataGridHeaderRow };
